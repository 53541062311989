import service from '@/utils/request2'
//七牛上传
export function file_uploads(data){
    return service({
        url:'/api/api/upload',
        method:'post',
        data
    })
}
export function getQiNiuToken(){
    return service({
        url:'/api/file/token',
        method:'get',
    })
}

