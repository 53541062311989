<template>
    <el-upload class="avatar-uploader" v-show="visible" v-model="myValue" action="" :accept="accept" :show-file-list="false"
        :before-upload="beforUpload" :http-request="qiniuUpload">
        <div v-if="type == 'mp4'">
            <video controls v-if="imageUrl" height="178px">
                <source :src="imageUrl">
                Sorry, your browser doesn't support embedded videos.
            </video>
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </div>
        <div v-else>
            <img v-if="imageUrl" :fit="cover" :src="imageUrl" width="178" height="178">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </div>



        <el-progress v-if="isShow" type="circle" :percentage="percent" :width="178" :show-text="false"
            color="#67C23A"></el-progress>
    </el-upload>
</template>
  
<script>

import { getQiNiuToken } from '@/api/commit2'
var qiniu = require('qiniu-js')
var uuid = require('uuid-js')
const putExtra = {
    fname: "",
    params: {},
    mimeType: null
}
const config = {
    useCdnDomain: true,
    region: qiniu.region.z1
}

export default {
    props: {
        imgUrl: {
            type: String,
            default: ''
        },
        visible: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            myValue: '',
            imageUrl: '',
            accept: 'image/*',
            percent: 0,
            isShow: false,
            cover: 'cover',
            type: ''
        }
    },
    methods: {
        beforUpload(file) {

        },
        async qiniuUpload(data) {
            this.type = ''
            console.log(data.file.type)
            if (data.file.type == 'video/mp4') {
                this.type = 'mp4'
                this.imageUrl = ''
            }
            var file = data.file
            var res = await getQiNiuToken()
            if (res.code == 200) {
                var token = res.data
            } else {
                this.$message.error('获取uptoken失败，请联系管理员')
                return false
            }
            var key = uuid.create().toString() + this.type
            this.isShow = true
            var observable = qiniu.upload(file, key, token, putExtra, config)
            var subscription = observable.subscribe(next => {
                this.percent = next.total.percent
            }, error => {

            }, complete => {
                var _this = this
                setTimeout(function () {
                    _this.isShow = false
                }, 2000)
                this.$message.success('上传成功');
                this.imageUrl = 'https://pic.cnosonline.com/' + complete.key
                this.myValue = 'https://pic.cnosonline.com/' + complete.key
            })
        }
    },
    mounted() {
    },

    watch: {
        myValue(val) {
            this.$emit('change', val)
        },
        imgUrl: {
            immediate: true,
            handler(val) {
                this.myValue = val
                this.imageUrl = val
                if (!(this.myValue.indexOf('mp4') == -1)) {
                    this.type = 'mp4'
                }
            },
        },
    },
}
</script>
  
<style lang="scss" scoped>
.avatar-uploader {
    width: 178px;
    height: 178px;
    position: relative;
    border: 2px dashed #d9d9d9;

    .el-progress--circle {
        position: absolute;
        top: 0;
        left: 0;
    }
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}

.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
</style>
  